import { Link } from "react-scroll";

export default function Anchor({ isSmallDevice }) {
  const handleSetActive = (to) => {
    // console.log(to);
  };

  return (
    <>
      <div className="anchor_list">
        <ul>
          <li>
            <Link
              activeClass="active"
              to="sec_1"
              spy={true}
              smooth={true}
              offset={isSmallDevice ? -44 : -80}
              duration={500}
              onSetActive={handleSetActive}
            >
              회사 소개
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="sec_2"
              spy={true}
              smooth={true}
              offset={isSmallDevice ? -44 : -80}
              duration={500}
              onSetActive={handleSetActive}
            >
              서비스
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="sec_3"
              spy={true}
              smooth={true}
              offset={isSmallDevice ? -44 : -80}
              duration={500}
              onSetActive={handleSetActive}
            >
              솔루션
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="sec_4"
              spy={true}
              smooth={true}
              offset={isSmallDevice ? -44 : -80}
              duration={500}
              onSetActive={handleSetActive}
            >
              비즈니스
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
