/** @format */

import Header from "../../components/Herder";
import Footer from "../../components/Footer";
import Reveal from "../../components/Reveal";
import { useState } from "react";
import { useEffect } from "react";

export default function Home() {
  const isSmallDevice = window.innerWidth <= 1080;

  return (
    <>
      <Header isSmallDevice={isSmallDevice} />
      <div className="section_area">
        <section id="sec_1">
          <div
            className="section_intro"
            style={{ backgroundImage: "URL(/images/bg_main.png)" }}
          >
            <div className="inner">
              <div className="section_head">
                <Reveal delay={0.25}>
                  <h1>
                    모두를 위한 AI 리빙 솔루션
                    <br />
                    커넥트파이 클라우드
                  </h1>
                </Reveal>
                <Reveal delay={0.5}>
                  <p>
                    커넥트 파이 클라우드는
                    <br className="m_hide" />
                    AI 기반 챗봇을
                    <br className="m_show" />
                    활용한 O2O 컨시어지 서비스 솔루션 <br className="m_show" />
                    및 <br className="m_hide" />
                    부동산 자산 관리 프롭테크 솔루션을&nbsp;
                    <br className="m_show" />
                    제공합니다.
                  </p>
                </Reveal>
              </div>
              <div className="section_body">
                {/* <div className="character">
                  <img src="/images/character_main.png" alt="ai_character" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="section_description">
            <div className="inner">
              <div className="section_head">
                <Reveal delay={0.2}>
                  <h1>
                    편의시설 연계 호텔 수준의
                    <br />
                    컨시어지 서비스 제공
                  </h1>
                </Reveal>
                <Reveal delay={0.6}>
                  <p>
                    커넥트파이클라우드는 다양한 형태의
                    <br className="m_show" /> 주거시설과&nbsp;
                    <br className="m_hide" />
                    주변 생활편의시설을
                    <br className="m_show" /> 연계하여 호텔 수준의&nbsp;
                    <br className="m_hide" />
                    컨시어지 서비스를 제공하기 위한 AI 기반 챗봇&nbsp;
                    <br className="m_hide" />
                    솔루션을
                    <br className="m_show" /> 제공합니다.
                  </p>
                </Reveal>
              </div>
            </div>
          </div>
        </section>
        <section id="sec_2">
          <div className="section_service">
            <div className="inner">
              <div className="section_head">
                <Reveal>
                  <em className="title_tag">서비스</em>
                  <h1>
                    커넥트파이 클라우드
                    <br />
                    주요 서비스
                  </h1>
                </Reveal>
                <Reveal delay={0.25}>
                  <ul>
                    <li>
                      기업형 임대관리 솔루션과 O2O생활서비스 통합 솔루션이 주요
                      서비스입니다.
                    </li>
                    <li>
                      기업형 임대관리 솔루션 50개 단지, 45,000호실 관리중입니다.
                    </li>
                  </ul>
                </Reveal>
              </div>
              <div className="section_body">
                <div className="service_grid">
                  <Reveal>
                    <div className="grid_head">
                      <h2>
                        O2O 생활서비스
                        <br />
                        통합 솔루션
                      </h2>
                      <p>호텔 수준의 컨시어지 서비스를 제공</p>
                      <span>2024 4Q 런칭</span>
                    </div>
                    <div className="grid_body">
                      {isSmallDevice ? (
                        <img
                          src="/images/m_img_washer.png"
                          alt="O2O 생활서비스 통합 솔루션"
                        />
                      ) : (
                        <img
                          src="/images/img_washer.png"
                          alt="O2O 생활서비스 통합 솔루션"
                        />
                      )}
                    </div>
                  </Reveal>
                  <Reveal delay={0.25}>
                    <div className="grid_body">
                      <img src="/images/img_interior.png" alt="interior" />
                    </div>
                  </Reveal>
                  <Reveal delay={0.5}>
                    <div className="grid_body">
                      <img src="/images/img_apps.png" alt="apps" />
                    </div>
                  </Reveal>
                  <Reveal delay={0.75}>
                    <div className="grid_head">
                      <h2>
                        eRoom PMS
                        <br />
                        기업형 임대 관리 솔루션
                      </h2>
                      <ul>
                        <li>
                          <h3>누적 고객사</h3>
                          <p>
                            <span>50</span>개
                          </p>
                        </li>
                        <li>
                          <h3>전체 호실</h3>
                          <p>
                            <span>45,000</span>호실
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="grid_body">
                      {isSmallDevice ? (
                        <img
                          src="/images/m_img_building.png"
                          alt="eRoom PMS 기업형 임대 관리 솔루션"
                        />
                      ) : (
                        <img
                          src="/images/img_building.png"
                          alt="eRoom PMS 기업형 임대 관리 솔루션"
                        />
                      )}
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="sec_3">
          <div className="section_solution">
            <div className="inner">
              <div className="section_head">
                <Reveal delay={0.1}>
                  <em className="title_tag">솔루션</em>
                  <h1>
                    오프라인 서비스 업체를 바로 호출
                    <br />
                    컨시어지 커넥트
                  </h1>
                </Reveal>
                <Reveal delay={0.25}>
                  <p>
                    O2O 플랫폼과 연계하여 고객의 복잡하고 다양한 요구를 이해하고
                    적절한 서비스를 제시하며 더 나아가서는
                    <br className="m_hide" />
                    오프라인
                    <br className="m_show" /> 서비스업체를 바로 호출해주는
                    서비스 맞춤형 챗봇 솔루션.
                  </p>
                </Reveal>
              </div>
              <div className="section_body">
                <Reveal delay={0.5}>
                  <div
                    className="solution_banner"
                    style={{
                      backgroundImage: "url(/images/bg_solution.png)",
                    }}
                  >
                    <h1>
                      고객의 요구를 이해하고 적절한 서비스를
                      <br className="m_hide" />
                      제시하는 맞춤형 챗봇 솔루션
                    </h1>
                  </div>
                </Reveal>
                <Reveal delay={0.75}>
                  <div
                    className="solution_banner_desc"
                    // style={{
                    //   backgroundImage: "url(/images/bg_solution_desc.png)",
                    // }}
                  >
                    <h1>
                      외부망과 단절된 보안 네트워크 안에서도
                      <br />
                      독립적으로 설치되어 운영이 가능하며 내부
                      <br className="m_show" />
                      에서
                      <br className="m_hide" />
                      생성된 데이터를 반영하여 지속적으로 재학습하는&nbsp;
                      <p>고객 맞춤형 챗봇 솔루션 : 컨시어지 커넥트</p>
                    </h1>
                    {isSmallDevice ? (
                      <img src="/images/m_banner_desc.png" alt="" />
                    ) : (
                      <img src="/images/img_banner_desc.png" alt="" />
                    )}
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
          <div className="banner">
            <img src="/images/bg_banner.png" alt="banner" />
          </div>
        </section>
        <section id="sec_4">
          <div className="section_card_list">
            <div className="inner">
              <div className="section_head">
                <Reveal>
                  <em className="title_tag">비즈니스</em>
                  <h1>
                    기업형 임대관리 업체들에게
                    <br />
                    <span>편의성을 제공</span>
                  </h1>
                </Reveal>
                <Reveal delay={0.2}>
                  <p>
                    임차인들을 위한 커뮤니티, 생활 정보 서비스
                    <span>(OIS 예정)</span>,&nbsp;
                    <br className="m_show" />
                    금융 정보 (TBD) 서비스를 제공
                  </p>
                </Reveal>
              </div>
              <div className="section_body">
                <ul className="card_list">
                  <li>
                    <Reveal delay={0.2}>
                      <div className="card_cont">
                        <h2>
                          <span>임대</span>
                          <span>주거 서비스</span>
                        </h2>
                        <ul>
                          <li>임대료/관리비 자동납부</li>
                          <li>시설물 및 비품 사용 예약</li>
                        </ul>
                        <p>
                          <img
                            src="/images/img_card01.png"
                            alt="임대,주거 서비스"
                          />
                        </p>
                      </div>
                      <p className="card_desc">
                        임차인의 주거편의 서비스 제공을 귀한 기반 서비스 구축
                        <br />
                        (결제, 예약, CS접수, 공지 외)
                      </p>
                    </Reveal>
                  </li>
                  <li>
                    <Reveal delay={0.4}>
                      <div className="card_cont">
                        <h2>
                          <span>커뮤니티 서비스</span>
                        </h2>
                        <ul>
                          <li>주거 정보 공유</li>
                          <li>주거자 간 커뮤니티</li>
                        </ul>
                        <p>
                          <img
                            src="/images/img_card02.png"
                            alt="커뮤니티 서비스"
                          />
                        </p>
                      </div>
                      <p className="card_desc">
                        관리업체(동 대표, 부녀회 외)의 주거 정보 공유,
                        중고(교환) 마켓, 친목회 등의 커뮤니티 서비스
                      </p>
                    </Reveal>
                  </li>
                  <li>
                    <Reveal delay={0.6}>
                      <div className="card_cont">
                        <h2>
                          <span>생활 정보 서비스</span>
                        </h2>
                        <ul>
                          <li>loT 서비스 연계</li>
                          <li>
                            이사, 세탁, 인테리어,&nbsp;
                            <br className="m_show" />
                            배달 서비스 연계 등
                          </li>
                        </ul>
                        <p>
                          <img
                            src="/images/img_card03.png"
                            alt="생활 정보 서비스"
                          />
                        </p>
                      </div>
                      <p className="card_desc">
                        ioT서비스 연계 / 이사, 세탁, 수리, 인테리어, 배달서비스
                        등 주변 생활 정보 서비스
                      </p>
                    </Reveal>
                  </li>
                  <li>
                    <Reveal delay={0.8}>
                      <div className="card_cont">
                        <h2>
                          <span>금융정보 서비스</span>
                        </h2>
                        <ul>
                          <li>개인화 금융 서비스</li>
                        </ul>
                        <p>
                          <img
                            src="/images/img_card04.png"
                            alt="금융정보 서비스"
                          />
                        </p>
                      </div>
                      <p className="card_desc">
                        거주자의 연령, 성별, 소비성향에 따른 금융상품 및 정보
                        서비스
                      </p>
                    </Reveal>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section_partner">
            <div className="inner">
              <div className="section_head">
                <h1>
                  글로벌 투자 파트너와
                  <br />
                  함께합니다.
                </h1>
              </div>
              <div className="section_body">
                <div className="img_partner">
                  {isSmallDevice ? (
                    <img src="/images/m_img_partner.png" alt="partner" />
                  ) : (
                    <img src="/images/img_partner.png" alt="partner" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="section_company">
            <div className="section_head">
              <img src="/images/img_yanolja.png" alt="사옥" />
            </div>
            <div className="section_body">
              <ul>
                <li>
                  <img
                    src="/images/logo_cfc_default.png"
                    alt="connectfy cloud"
                    className="company_logo"
                  />
                </li>
                <li>
                  <dl>
                    <dt>설립년월</dt>
                    <dd>2023년 4월 20일</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>대표이사</dt>
                    <dd>한유순</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>직원수</dt>
                    <dd>30명 (2024년 기준)</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>사업소재</dt>
                    <dd>서울특별시 강남구 봉은사로30길 76, OFFiCE B 역삼</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>주요사업</dt>
                    <dd>클라우드 기반 주거 및 생활 서비스 중개업</dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <Footer isSmallDevice={isSmallDevice} />
    </>
  );
}
