import Anchor from "./Anchor";
// import ScrollTop from "./ScrollTop";

export default function Header({ isSmallDevice }) {
  return (
    <header className="header">
      <div className="inner">
        <h1 className="logo">
          {isSmallDevice ? (
            <img
              src="/images/m_logo_cfc_default.png"
              alt="logo connectfy cloud"
            />
          ) : (
            <img
              src="/images/logo_cfc_default.png"
              alt="logo connectfy cloud"
            />
          )}
        </h1>
        <nav className="gnb">
          <Anchor isSmallDevice={isSmallDevice} />
        </nav>
      </div>
      {/* <ScrollTop /> */}
    </header>
  );
}
