// import { Link } from "react-router-dom";

export default function Footer({ isSmallDevice }) {
  return (
    <footer className="footer">
      <div className="inner">
        <div className="footer_head">
          <h1 className="logo">
            <img src="/images/logo_cfc_white.png" alt="logo connectfy cloud" />
          </h1>
          <div className="footer_content">
            <ul className="address">
              <li>
                <span className="ceo">
                  <strong>대표이사 한유순</strong>
                </span>
                <span>
                  <strong>사업자등록번호</strong> 675-86-03014
                </span>
              </li>
              <li>
                <span>
                  <strong>주소</strong>서울특별시 강남구 봉은사로30길 76, OFFiCE B 역삼
                </span>
                <span>
                  <strong>고객센터</strong>02-6203-7450
                </span>
                <span>
                  <strong>제휴문의</strong>business@connectfy.cloud
                </span>
              </li>
            </ul>
            <p className="copyright">
              {isSmallDevice ? null : "Copyright"} © CONNECTFY CLOUD CORP. ALL
              RIGHT RESERVED.
            </p>
          </div>
        </div>
        {/* <div className="footer_body">
          <ul>
            <li>
              <Link>개인정보처리방침</Link>
            </li>
            <li>
              <Link>이용약관</Link>
            </li>
          </ul>
        </div> */}
      </div>
    </footer>
  );
}
